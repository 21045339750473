const KEY_PERMISSIONS = "PERMISSIONS";
const KEY_PROCESSINGPOST = "PROCESSINGPOST";

const KEY_TINYMCE = [
  "38ve7vm3t7b1shm03wbaqobmpr8mbhh9irnw0yrzkg51gexn",
  "ragrrsy9lfq0llg1pdyh16kf4dqrtbz3xqrtn46wpxc39hn9",
  "wte4ytpuz75hetzkej7am8rspnjxvwtudzsn1er4t41bmtuo",
  "smkht5xawe0sx5d0p9n972ln75mobogp79itsygnbk7nsadb",
  "gcjdfedmn67ygodt1kp9mxtyo0cqijtknixh45f7edx7yak2",
  "hdpcgduenluwyovpbjndqto90y3q7m40mql4qrnsqo0jvfel",
];

const NEW_KEY_TINYMCE = [
  "prq4777g3zx0wmqfkrmebuvcy03clb6glbvlvy4bq1k8e57v",
  "y6n624r1podibenhsqh4cidrdiqt6id50hv6geat72w57k64",
  "w1bf89koixk4nod3kia77g3q4h4hkz53dbhvu0jxdh4qcif4",
  "0hp6n64tq17sl6t4r38bsm2z7owve3mvsc4f5waurc7kccnl",
  "dzeu8g4uimzdaftuq9b6xxbl4sr2iqrofvl3om1o5ua65719",
  "2bdikcopl726fhan1y8rkalqytluwr29jptekssdfj3iblxu",
  "79j1eg14naxe2g9czyc877dbjbolg014nj2iysxww3zepnbr",
  "lz97sstdylue7d8api2uoz5yr0i0ce3ohjiogd74qaoxy87b",
  "ytxuxtnscecfdz9jk1rlxoxqboxfvsu8wh6yb5prafnl7m0u",
  "jkrr26u76wpi0ag3ev6v0hh1xmdid5ml44nnlvp3peeu775v",
  "gaf4rus9lg33wt081ai4m2olznzdfgpb6dg0r28b76ep8qcr"
];

export const getRotatingKey = () => {
  const keys = NEW_KEY_TINYMCE;
  const currentDate = new Date().getDate();
  const keyIndex = (currentDate - 1) % keys.length;
  return keys[keyIndex];
};

export const getRandomKey = () => {
  const keys = NEW_KEY_TINYMCE;
  const keyIndex = Math.floor(Math.random() * keys.length);
  return keys[keyIndex];
};

export const setUserPermissions = (permissions) => {
  localStorage.setItem(KEY_PERMISSIONS, JSON.stringify(permissions));
};

export const setUserProcessingPost = (processing) => {
  localStorage.setItem(KEY_PROCESSINGPOST, JSON.stringify(processing));
};

export const getUserPermissions = () => {
  try {
    const value = localStorage.getItem(KEY_PERMISSIONS);
    return typeof value === "string" ? JSON.parse(value) : null;
  } catch (error) {
    return null;
  }
};
export const setUserInfo = async (userInfo) => {
  localStorage.setItem("USER_INFO", JSON.stringify(userInfo));
  return true;
};

export const getUserInfo = () => {
  try {
    const value = localStorage.getItem("USER_INFO");
    if (typeof value === "string") {
      return JSON.parse(value);
    }
    return "";
  } catch (error) {
    return "";
  }
};

export const funcPost = [
  {
    label: "Danh sách tin bài",
    value: "danhsachtinbai",
  },
  {
    label: "Chuyên mục",
    value: "chuyenmuc",
  },
  {
    label: "Nguồn tin",
    value: "nguontin",
  },
  {
    label: "Bình luận",
    value: "binhluan",
  },
];

export const postStatus = [
  {
    label: "Nháp",
    value: "0",
  },
  {
    label: "Chờ duyệt",
    value: "1",
  },
  {
    label: "Cần duyệt",
    value: "2",
  },
  {
    label: "Chờ xuất bản",
    value: "3",
  },
  {
    label: "Xuất bản",
    value: "4",
  },
  {
    label: "Từ chối",
    value: "5",
  },
  {
    label: "Bị từ chối",
    value: "6",
  },
  {
    label: "Huỷ xuất bản",
    value: "7",
  },
];

export const royaltyType = [
  {
    label: "Nhuận bút tin bài",
    value: "0",
  },
  {
    label: "Nhuận bút đa phương tiện",
    value: "1",
  },
  {
    label: "Nhuận bút đăng tải",
    value: "2",
  },
  {
    label: "Kinh phí hỗ trợ",
    value: "3",
  },
];

export const questionStatus = [
  {
    label: "Chưa trả lời",
    value: "0",
    key: "NOT_ANSWERED",
  },
  {
    label: "Chờ duyệt",
    value: "1",
    key: "PENDING",
  },
  {
    label: "Đã duyệt",
    value: "2",
    key: "APPROVED",
  },
  {
    label: "Đã xuất bản",
    value: "3",
    key: "PUBLISHED",
  },
  {
    label: "Từ chối",
    value: "4",
    key: "REFUSED",
  },
  {
    label: "Chờ trả lời",
    value: "5",
    key: "WAITING_FOR_ANSWER",
  },
  {
    label: "Đã trả lời",
    value: "6",
    key: "ANSWERED",
  },
  {
    label: "Xác nhận đã trả lời",
    value: "7",
    key: "CONFIRM_ANSWERED",
  },
];

export const questionStatusExternal = [
  {
    label: "Chưa trả lời",
    value: "0",
    key: "NOT_ANSWERED",
  },
  {
    label: "Đã trả lời",
    value: "1",
    key: "ANSWERED",
  },
  {
    label: "Chờ duyệt",
    value: "2",
    key: "PENDING",
  },
  {
    label: "Đã duyệt",
    value: "3",
    key: "APPROVED",
  },
  {
    label: "Xuất bản",
    value: "4",
    key: "PUBLISHED",
  },
  {
    label: "Từ chối",
    value: "5",
    key: "REFUSE",
  },
  {
    label: "Chờ trả lời",
    value: "6",
    key: "WAITING_FOR_ANSWER",
  },
  {
    label: "Xác nhận đã trả lời",
    value: "7",
    key: "CONFIRM_ANSWERED",
  },
];


export const commentStatus = [
  {
    label: "Chờ duyệt",
    value: "0",
    key: "STATUS_PENDING",
  },
  {
    label: "Hiển thị",
    value: "1",
    key: "STATUS_SHOW",
  },
  {
    label: "Spam",
    value: "2",
    key: "STATUS_SPAM",
  },
  {
    label: "Spam",
    value: "3",
    key: "STATUS_DELETED",
  },
];

export const citizenScheduleStatus = [
  {
    label: "Nháp",
    value: "0",
    key: "DRAFT",
  },
  {
    label: "Chờ duyệt",
    value: "1",
    key: "PENDING",
  },
  {
    label: "Cần duyệt",
    value: "2",
    key: "NEED_APPROVE",
  },
  {
    label: "Chờ xuất bản",
    value: "3",
    key: "PENDING_PUBLISH",
  },
  {
    label: "Đã xuất bản",
    value: "4",
    key: "PUBLISHED",
  },
  {
    label: "Từ chối",
    value: "5",
    key: "REFUSE",
  },
  {
    label: "Bị từ chối",
    value: "6",
    key: "REFUSED",
  },
];

export const bannerPositions = [
  {
    label: "Đầu trang",
    value: "0",
    key: "HEAD",
  },
  {
    label: "Chân trang",
    value: "1",
    key: "FOOTER",
  },
  {
    label: "Block trái",
    value: "2",
    key: "LEFT_BANNER",
  },
  {
    label: "Block phải",
    value: "1",
    key: "RIGHT_BANNER",
  },
];

export const statusAlbum = {
  STATUS_APPROVED: "Đã duyệt",
  STATUS_PENDING: "Chờ duyệt",
};

export const monthOfYear = [
  {
    label: "Tháng 1",
    value: 1,
  },
  {
    label: "Tháng 2",
    value: 2,
  },
  {
    label: "Tháng 3",
    value: 3,
  },
  {
    label: "Tháng 4",
    value: 4,
  },
  {
    label: "Tháng 5",
    value: 5,
  },
  {
    label: "Tháng 6",
    value: 6,
  },
  {
    label: "Tháng 7",
    value: 7,
  },
  {
    label: "Tháng 8",
    value: 8,
  },
  {
    label: "Tháng 9",
    value: 9,
  },
  {
    label: "Tháng 10",
    value: 10,
  },
  {
    label: "Tháng 11",
    value: 11,
  },
  {
    label: "Tháng 12",
    value: 12,
  },
];

export const userRole = {
  collaborators_role: "Cộng tác viên",
  secretary_role: "Thư ký",
  editorialDirector_role: "Tổng biên tập",
  editorialDirector_dn_role: "Tổng biên tập đối ngoại",
  editor_role: "Biên tập viên",
};

export const languages = {
  vietnamese: "vi",
  english: "en",
};

export const generateYearOptions = (startYear) => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = startYear; i <= currentYear; i++) {
    years.push({
      label: i.toString(),
      value: i,
    });
  }

  return years;
};

export const positionBBT = [
  {
    key: "CHIEF",
    value: "0",
    label: "Trưởng ban",
  },
  {
    key: "DEPUTY_CHIEF",
    value: "1",
    label: "Phó Trưởng ban",
  },
  {
    key: "MEMBER",
    value: "2",
    label: "Uỷ viên",
  },
  {
    key: "SECRETARY",
    value: "3",
    label: "Thư ký",
  },
];

export const typeMediaPersonal = [
  {
    label: "Ảnh",
    value: "image",
  },
  {
    label: "Video",
    value: "video",
  },
  {
    label: "Audio",
    value: "audio",
  },
  {
    label: "Tài liệu",
    value: "doc",
  },
];

export const roleData = [
  { key: "createPost", label: "Tạo bài viết mới", type: "post" },
  { key: "deletePost", label: "Xóa bài viết", type: "post" },
  { key: "deleteOwnPost", label: "Xóa bài viết do bản thân tạo", type: "post" },
  { key: "indexPost", label: "Danh sách bài viết", type: "post" },
  { key: "pinPost", label: "Ghim bài viết lên trang thành viên", type: "post" },
  {
    key: "publishPost",
    label: "Xuất bản bài viết để mọi người có thể xem",
    type: "post",
  },
  { key: "refusePost", label: "Từ chối xuất bản bài viết", type: "post" },
  { key: "sendPost", label: "Gửi bài viết để chờ duyệt", type: "post" },
  {
    key: "toggleDisplayPost",
    label: "Hiện thị hoặc ẩn bài đăng",
    type: "post",
  },
  {
    key: "updatePost",
    label: "Cập nhật nội dung bài viết đã có",
    type: "post",
  },
  { key: "viewPost", label: "Xem bài viết", type: "post" },
  {
    key: "memberPost",
    label: "Tin bài từ trang thành viên",
    type: "post",
  },
  {
    key: "createCategory",
    label: "Tạo chuyên mục tin bài mới",
    type: "category",
  },
  { key: "deleteCategory", label: "Xóa chuyên mục tin bài", type: "category" },
  {
    key: "manageCategory",
    label: "Quản lý chuyên mục tin bài (bao gồm tạo, xóa, cập nhật)",
    type: "category",
  },
  {
    key: "indexCategory",
    label: "Xem danh sách chuyên mục tin bài",
    type: "category",
  },
  {
    key: "updateCategory",
    label: "Cập nhật thông tin chuyên mục tin bài",
    type: "category",
  },
  //* TODO: crawl
  {
    key: "createCrawlDomain",
    label: "Tạo miền mới để thu thập bài viết",
    type: "crawl_post",
  },
  {
    key: "deleteCrawlDomain",
    label: "Xóa một miền khỏi danh sách thu thập",
    type: "crawl_post",
  },
  {
    key: "indexCrawlDomain",
    label: "Danh sách miền để theo dõi và quản lý",
    type: "crawl_post",
  },
  {
    key: "scanNowCrawlDomain",
    label: "Bước thu thập bài viết từ một miền ngay lập tức",
    type: "crawl_post",
  },
  {
    key: "updateCrawlDomain",
    label: "Cập nhật thông tin của một miền thu thập",
    type: "crawl_post",
  },
  {
    key: "deleteCrawlDomainUrl",
    label: "Xóa URL bài viết có thể khỏi danh sách thu thập",
    type: "crawl_post",
  },
  {
    key: "indexCrawlDomainUrl",
    label: "Thêm URL bài viết vào danh sách để thu thập nội dung",
    type: "crawl_post",
  },
  {
    key: "createCrawlKeyword",
    label: "Tạo từ khóa mới để thu thập bài viết liên quan",
    type: "crawl_post",
  },
  {
    key: "deleteCrawlKeyword",
    label: "Xóa một từ khóa khỏi danh sách thu thập",
    type: "crawl_post",
  },
  {
    key: "indexCrawlKeyword",
    label: "Thêm từ khóa vào danh sách để thu thập bài viết theo chủ đề",
    type: "crawl_post",
  },
  {
    key: "updateCrawlKeyword",
    label: "Cập nhật thông tin của một từ khóa thu thập",
    type: "crawl_post",
  },
  {
    key: "deleteCrawlPost",
    label: "Xóa bài viết đã thu thập",
    type: "crawl_post",
  },
  {
    key: "indexCrawlPost",
    label: "Danh sách bài viết đã thu thập",
    type: "crawl_post",
  },
  {
    key: "takeCrawlPost",
    label: "Lấy về bài viết đã thu thập",
    type: "crawl_post",
  },
  {
    key: "viewCrawlPost",
    label: "Xem nội dung bài viết đã thu thập",
    type: "crawl_post",
  },
  //*TODO: posted content
  {
    key: "createPosted",
    label: "Tạo bài viết nội dung đăng tải",
    type: "posted_content",
  },
  {
    key: "deletePosted",
    label: "Xóa bài viết nội dung đăng tải",
    type: "posted_content",
  },
  {
    key: "indexPosted",
    label: "Danh sách bài viết nội dung đăng tải",
    type: "posted_content",
  },
  {
    key: "publishPosted",
    label: "Xuất bản bài viết nội dung đăng tải",
    type: "posted_content",
  },
  {
    key: "refusePosted",
    label: "Từ chối xuất bản bài viết nội dung đăng tải",
    type: "posted_content",
  },
  {
    key: "sendPosted",
    label: "Gửi bài viết nội dung đăng tải để duyệt",
    type: "posted_content",
  },
  {
    key: "toggleDisplayPosted",
    label: "Hiển thị hoặc ẩn bài đăng.",
    type: "posted_content",
  },
  {
    key: "updatePosted",
    label: "Cập nhật nội dung bài viết nội dung đăng tải",
    type: "posted_content",
  },
  {
    key: "viewPosted",
    label: "Xem bài viết nội dung đăng tải",
    type: "posted_content",
  },
  {
    key: "createContentCategory",
    label: "Tạo chuyên mục nội dung mới.",
    type: "posted_content",
  },
  {
    key: "deleteContentCategory",
    label: "Xóa chuyên mục nội dung.",
    type: "posted_content",
  },
  {
    key: "filterContentCategory",
    label: "Lọc danh sách chuyên mục nội dung theo tiêu chí.",
    type: "posted_content",
  },
  {
    key: "indexContentCategory",
    label: "Xem danh sách chuyên mục nội dung.",
    type: "posted_content",
  },
  {
    key: "updateContentCategory",
    label: "Cập nhật thông tin chuyên mục nội dung.",
    type: "posted_content",
  },
  //* TODO: văn bản
  {
    key: "createExecutiveDirection",
    label: "Tạo văn bản điện tử mới",
    type: "directive",
  },
  {
    key: "deleteExecutiveDirection",
    label: "Xóa văn bản điện tử",
    type: "directive",
  },
  {
    key: "indexExecutiveDirection",
    label: "Danh sách văn bản điện tử",
    type: "directive",
  },
  {
    key: "publishExecutiveDirection",
    label: "Xuất bản văn bản để mọi người có thể xem",
    type: "directive",
  },
  {
    key: "refuseExecutiveDirection",
    label: "Từ chối xuất bản văn bản điện tử",
    type: "directive",
  },
  {
    key: "sendExecutiveDirection",
    label: "Gửi duyệt văn bản điện tử",
    type: "directive",
  },
  {
    key: "toggleDisplayExecutiveDirection",
    label: "Hiển thị hoặc ẩn văn bản đã xuất bản.",
    type: "directive",
  },
  {
    key: "updateExecutiveDirection",
    label: "Cập nhật nội dung văn bản đã có",
    type: "directive",
  },
  {
    key: "viewExecutiveDirection",
    label: "Xem văn bản, bao gồm tất cả trạng thái",
    type: "directive",
  },
  {
    key: "createDocumentType",
    label: "Tạo loại văn bản mới.",
    type: "directive",
  },
  { key: "deleteDocumentType", label: "Xóa loại văn bản.", type: "directive" },
  {
    key: "indexDocumentType",
    label: "Xem danh sách loại văn bản.",
    type: "directive",
  },
  {
    key: "updateDocumentType",
    label: "Cập nhật thông tin loại văn bản.",
    type: "directive",
  },
  {
    key: "viewDocumentType",
    label: "Xem thông tin chi tiết về loại văn bản.",
    type: "directive",
  },
  {
    key: "createDocumentField",
    label: "Tạo lĩnh vực mới cho văn bản.",
    type: "directive",
  },
  {
    key: "deleteDocumentField",
    label: "Xóa lĩnh vực khỏi văn bản.",
    type: "directive",
  },
  {
    key: "indexDocumentField",
    label: "Xem danh sách lĩnh vực cho văn bản.",
    type: "directive",
  },
  {
    key: "updateDocumentField",
    label: "Cập nhật thông tin lĩnh vực của văn bản.",
    type: "directive",
  },
  {
    key: "viewDocumentField",
    label: "Xem thông tin chi tiết về lĩnh vực của văn bản.",
    type: "directive",
  },
  {
    key: "createDocumentPublicationDepartment",
    label: "Tạo mới cơ quan ban hành văn bản.",
    type: "directive",
  },
  {
    key: "deleteDocumentPublicationDepartment",
    label: "Xóa cơ quan ban hành văn bản.",
    type: "directive",
  },
  {
    key: "indexDocumentPublicationDepartment",
    label: "Xem danh sách cơ quan ban hành văn bản.",
    type: "directive",
  },
  {
    key: "updateDocumentPublicationDepartment",
    label: "Cập nhật thông tin cơ quan ban hành văn bản.",
    type: "directive",
  },
  {
    key: "viewDocumentPublicationDepartment",
    label: "Xem thông tin chi tiết về cơ quan ban hành văn bản.",
    type: "directive",
  },
  {
    key: "createDocumentSigner",
    label: "Thêm người ký mới cho văn bản.",
    type: "directive",
  },
  {
    key: "deleteDocumentSigner",
    label: "Xóa người ký khỏi văn bản.",
    type: "directive",
  },
  {
    key: "indexDocumentSigner",
    label: "Xem danh sách người ký của văn bản.",
    type: "directive",
  },
  {
    key: "updateDocumentSigner",
    label: "Cập nhật thông tin người ký cho văn bản.",
    type: "directive",
  },
  {
    key: "viewDocumentSigner",
    label: "Xem thông tin chi tiết về người ký của văn bản.",
    type: "directive",
  },
  //* TODO: draft document
  { key: "createDraft", label: "Tạo dự thảo mới.", type: "draft" },
  { key: "deleteDraft", label: "Xóa dự thảo.", type: "draft" },
  { key: "indexDraft", label: "Danh sách dự thảo", type: "draft" },
  {
    key: "publishDraft",
    label: "Xuất bản dự thảo để mọi người có thể xem.",
    type: "draft",
  },
  { key: "refuseDraft", label: "Từ chối xuất bản dự thảo.", type: "draft" },
  { key: "sendDraft", label: "Gửi dự thảo để duyệt", type: "draft" },
  {
    key: "toggleDisplayDraft",
    label: "Hiển thị hoặc ẩn dự thảo.",
    type: "draft",
  },
  {
    key: "updateDraft",
    label: "Cập nhật nội dung dự thảo đã có.",
    type: "draft",
  },
  { key: "viewDraft", label: "Xem dự thảo.", type: "draft" },
  {
    key: "indexDocumentType",
    label: "Xem danh sách loại văn bản.",
    type: "draft",
  },
  {
    key: "indexDocumentField",
    label: "Xem danh sách lĩnh vực cho văn bản.",
    type: "draft",
  },
  {
    key: "deleteFeedbackDraft",
    label: "Xóa góp ý cho dự thảo.",
    type: "draft",
  },
  {
    key: "indexFeedbackDraft",
    label: "Danh sách góp ý cho dự thảo",
    type: "draft",
  },
  { key: "showFeedbackDraft", label: "Xem góp ý cho dự thảo.", type: "draft" },
  { key: "spamFeedbackDraft", label: "Đánh dấu góp ý là spam.", type: "draft" },
  //* TODO: media
  {
    key: "approveImageLibrary",
    label: "Phê duyệt thêm ảnh vào thư viện.",
    type: "media",
  },
  { key: "createImageLibrary", label: "Tạo thư viện ảnh mới.", type: "media" },
  { key: "deleteImageLibrary", label: "Xóa thư viện ảnh.", type: "media" },
  {
    key: "indexImageLibrary",
    label: "Lập chỉ mục thư viện ảnh để dễ dàng tìm kiếm.",
    type: "media",
  },
  {
    key: "refuseImageLibrary",
    label: "Từ chối thêm ảnh vào thư viện.",
    type: "media",
  },
  {
    key: "sendImageLibrary",
    label: "Gửi thư viện ảnh để duyệt.",
    type: "media",
  },
  {
    key: "updateImageLibrary",
    label: "Cập nhật thông tin thư viện ảnh.",
    type: "media",
  },
  { key: "viewImageLibrary", label: "Xem thư viện ảnh.", type: "media" },
  { key: "deleteImageContent", label: "Xóa nội dung hình ảnh.", type: "media" },
  {
    key: "deleteOwnImageContent",
    label: "Xóa nội dung hình ảnh do mình tạo.",
    type: "media",
  },
  {
    key: "updateImageContent",
    label: "Cập nhật nội dung hình ảnh.",
    type: "media",
  },
  {
    key: "updateOwnImage",
    label: "Cập nhật hình ảnh do mình tạo.",
    type: "media",
  },
  {
    key: "uploadImageContent",
    label: "Tải lên nội dung hình ảnh.",
    type: "media",
  },
  { key: "viewImageContent", label: "Xem nội dung hình ảnh.", type: "media" },
  { key: "approveAudioContent", label: "Phê duyệt file audio", type: "media" },
  { key: "deleteAudioContent", label: "Xóa file audio", type: "media" },
  {
    key: "indexAudioContent",
    label: "Xem danh sách file audio",
    type: "media",
  },
  { key: "refuseAudioContent", label: "Từ chối file audio", type: "media" },
  { key: "sendAudioContent", label: "Gửi file audio để duyệt.", type: "media" },
  { key: "uploadAudioContent", label: "Tải file audio lên", type: "media" },
  { key: "viewAudioContent", label: "Xem file audio", type: "media" },
  {
    key: "updateOwnAudioContent",
    label: "Cập nhật thông tin file audio (bản thân tạo)",
    type: "media",
  },
  {
    key: "updateAudioContent",
    label: "Cập nhật thông tin file audio",
    type: "media",
  },
  { key: "approveVideoContent", label: "Phê duyệt file video.", type: "media" },
  { key: "deleteVideoContent", label: "Xóa file video.", type: "media" },
  {
    key: "indexVideoContent",
    label: "Xem danh sách file video",
    type: "media",
  },
  {
    key: "updateOwnVideoContent",
    label: "Cập nhật thông tin file Video (bản thân tạo)",
    type: "media",
  },

  { key: "refuseVideoContent", label: "Từ chối file video", type: "media" },
  {
    key: "sendVideoContent",
    label: "Gửi nội dung video để duyệt.",
    type: "media",
  },
  {
    key: "uploadVideoContent",
    label: "Tải nội dung video lên.",
    type: "media",
  },
  { key: "viewVideoContent", label: "Xem file video", type: "media" },
  {
    key: "updateVideoContent",
    label: "Cập nhật thông tin file video",
    type: "media",
  },
  //* TODO: comment
  {
    key: "indexComment",
    label: "Lập chỉ mục bình luận để dễ dàng tìm kiếm.",
    type: "comment",
  },
  { key: "deleteComment", label: "Xóa bình luận.", type: "comment" },
  {
    key: "restoreComment",
    label: "Khôi phục bình luận đã xóa.",
    type: "comment",
  },
  { key: "showComment", label: "Hiển thị bình luận.", type: "comment" },
  { key: "spamComment", label: "Đánh dấu bình luận là spam.", type: "comment" },
  //* TODO: Conversation
  {
    key: "createConversationOnline",
    label: "Tạo đối thoại trực tuyến",
    type: "conversation",
  },
  {
    key: "deleteConversationOnline",
    label: "Xóa đối thoại trực tuyến",
    type: "conversation",
  },
  {
    key: "indexConversationOnline",
    label: "Danh sách đối thoại trực tuyến",
    type: "conversation",
  },
  {
    key: "publishConversationOnline",
    label: "Xuất bản đối thoại trực tuyến",
    type: "conversation",
  },
  {
    key: "refuseConversationOnline",
    label: "Từ chối xuất bản đối thoại trực tuyến",
    type: "conversation",
  },
  {
    key: "sendConversationOnline",
    label: "Gửi đối thoại trực tuyến để duyệt.",
    type: "conversation",
  },
  {
    key: "toggleDisplayConversationOnline",
    label: "Hiển thị hoặc ẩn đối thoại trực tuyến",
    type: "conversation",
  },
  {
    key: "updateConversationOnline",
    label: "Cập nhật nội dung đối thoại trực tuyến",
    type: "conversation",
  },
  {
    key: "viewConversationOnline",
    label: "Xem đối thoại trực tuyến",
    type: "conversation",
  },
  {
    key: "createConversationOffline",
    label: "Tạo đối thoại ngoại tuyến",
    type: "conversation",
  },
  {
    key: "viewConversationOffline",
    label: "Xem chi tiết đối thoại ngoại tuyến",
    type: "conversation",
  },
  {
    key: "updateConversationOffline",
    label: "Chỉnh sửa đối thoại ngoại tuyến",
    type: "conversation",
  },
  {
    key: "deleteConversationOffline",
    label: "Xóa đối thoại ngoại tuyến",
    type: "conversation",
  },
  {
    key: "indexConversationOffline",
    label: "Danh sách đối thoại ngoại tuyến",
    type: "conversation",
  },
  {
    key: "publishConversationOffline",
    label: "Xuất bản đối thoại ngoại tuyến để mọi người có thể xem",
    type: "conversation",
  },
  {
    key: "refuseConversationOffline",
    label: "Từ chối xuất bản đối thoại ngoại tuyến",
    type: "conversation",
  },
  {
    key: "sendConversationOffline",
    label: "Gửi đối thoại ngoại tuyến để duyệt.",
    type: "conversation",
  },
  {
    key: "toggleDisplayConversationOffline",
    label: "Hiển thị hoặc ẩn đối thoại ngoại tuyến",
    type: "conversation",
  },
  {
    key: "createConversationTopic",
    label: "Tạo chủ đề cho các đối thoại",
    type: "conversation",
  },
  {
    key: "deleteConversationTopic",
    label: "Xóa chủ đề đối thoại",
    type: "conversation",
  },
  {
    key: "indexConversationTopic",
    label: "Danh sách chủ đề",
    type: "conversation",
  },
  {
    key: "viewConversationTopic",
    label: "Xem các chủ đề đối thoại.",
    type: "conversation",
  },
  //* TODO: Question_anser
  { key: "answerQuestion", label: "Trả lời câu hỏi.", type: "question_answer" },
  {
    key: "confirmAnswerQuestion",
    label: "Xác nhận câu trả lời là chính thức",
    type: "question_answer",
  },
  { key: "deleteQuestion", label: "Xóa câu hỏi.", type: "question_answer" },
  {
    key: "indexQuestion",
    label: "Danh sách câu hỏi",
    type: "question_answer",
  },
  {
    key: "moveQuestion",
    label: "Di chuyển câu hỏi đến danh mục khác",
    type: "question_answer",
  },
  {
    key: "publishQuestion",
    label: "Xuất bản câu hỏi",
    type: "question_answer",
  },
  {
    key: "recallQuestion",
    label: "Rút lại câu hỏi đã gửi",
    type: "question_answer",
  },
  {
    key: "refuseQuestion",
    label: "Từ chối trả lời câu hỏi",
    type: "question_answer",
  },
  {
    key: "removeFileQuestion",
    label: "Xóa file đính kèm trong câu hỏi.",
    type: "question_answer",
  },
  { key: "sendQuestion", label: "Gửi duyệt câu hỏi.", type: "question_answer" },
  {
    key: "toggleDisplayQuestion",
    label: "Hiển thị hoặc ẩn câu hỏi",
    type: "question_answer",
  },
  {
    key: "updateQuestion",
    label: "Cập nhật nội dung câu hỏi",
    type: "question_answer",
  },
  {
    key: "viewQuestion",
    label: "Xem câu hỏi",
    type: "question_answer",
  },
  //* TODO: recommendation
  {
    key: "createReflectRecommendationTopic",
    label: "Tạo chủ đề mới cho phản ánh kiến nghị",
    type: "recommendation",
  },
  {
    key: "deleteReflectRecommendationTopic",
    label: "Xóa chủ đề phản ánh kiến nghị",
    type: "recommendation",
  },
  {
    key: "indexReflectRecommendationTopic",
    label: "Lập chỉ mục các chủ đề phản ánh kiến nghị để dễ dàng tìm kiếm.",
    type: "recommendation",
  },
  {
    key: "updateReflectRecommendationTopic",
    label: "Cập nhật nội dung chủ đề phản ánh kiến nghị",
    type: "recommendation",
  },
  {
    key: "viewReflectRecommendationTopic",
    label: "Xem các chủ đề phản ánh kiến nghị",
    type: "recommendation",
  },
  {
    key: "answerReflectRecommendation",
    label: "Trả lời phản ánh kiến nghị",
    type: "recommendation",
  },
  {
    key: "confirmAnswerReflectRecommendation",
    label: "Xác nhận câu trả lời là chính thức",
    type: "recommendation",
  },
  {
    key: "deleteReflectRecommendation",
    label: "Xóa phần phản ánh kiến nghị",
    type: "recommendation",
  },
  {
    key: "indexReflectRecommendation",
    label: "Xem danh sách phản ánh kiến nghị",
    type: "recommendation",
  },
  {
    key: "moveReflectRecommendation",
    label: "Di chuyển phản hồi/đề xuất đến chủ đề khác",
    type: "recommendation",
  },
  {
    key: "publishReflectRecommendation",
    label: "Xuất bản phản hồi/đề xuất",
    type: "recommendation",
  },
  {
    key: "refuseReflectRecommendation",
    label: "Từ chối phản hồi/đề xuất",
    type: "recommendation",
  },
  {
    key: "saveAndPublishReflectRecommendation",
    label: "Lưu và xuất bản phản hồi/đề xuất",
    type: "recommendation",
  },
  {
    key: "sendReflectRecommendation",
    label: "Gửi phản hồi/đề xuất.",
    type: "recommendation",
  },
  {
    key: "toggleDisplayReflectRecommendation",
    label: "Hiển thị hoặc ẩn phản hồi/đề xuất",
    type: "recommendation",
  },
  {
    key: "updateReflectRecommendation",
    label: "Cập nhật nội dung phản hồi/đề xuất",
    type: "recommendation",
  },
  {
    key: "viewReflectRecommendation",
    label: "Xem các phản hồi/đề xuất",
    type: "recommendation",
  },
  //* Leader schedule
  {
    key: "createLeaderWorkingSchedule",
    label: "Tạo lịch công tác cho lãnh đạo.",
    type: "leader_schedule",
  },
  {
    key: "deleteLeaderWorkingSchedule",
    label: "Xóa lịch công tác của lãnh đạo.",
    type: "leader_schedule",
  },
  {
    key: "indexLeaderWorkingSchedule",
    label: "Danh sách lịch công tác của lãnh đạo.",
    type: "leader_schedule",
  },
  {
    key: "updateLeaderWorkingSchedule",
    label: "Cập nhật lịch công tác của lãnh đạo.",
    type: "leader_schedule",
  },
  {
    key: "viewLeaderWorkingSchedule",
    label: "Xem lịch công tác của lãnh đạo.",
    type: "leader_schedule",
  },
  //* hotline
  { key: "createHotline", label: "Tạo đường dây nóng mới.", type: "hotline" },
  { key: "deleteHotline", label: "Xóa đường dây nóng.", type: "hotline" },
  {
    key: "indexHotline",
    label: "Danh sách hotline.",
    type: "hotline",
  },
  {
    key: "toggleDisplayHotline",
    label: "Hiển thị hoặc ẩn đường dây nóng.",
    type: "hotline",
  },
  { key: "viewHotline", label: "Xem các đường dây nóng.", type: "hotline" },
  //* E contact
  { key: "createContact", label: "Tạo liên hệ mới.", type: "e_contact" },
  { key: "deleteContact", label: "Xóa liên hệ.", type: "e_contact" },
  {
    key: "indexContact",
    label: "Danh sách liên hệ.",
    type: "e_contact",
  },
  {
    key: "updateContact",
    label: "Cập nhật thông tin liên hệ.",
    type: "e_contact",
  },
  { key: "viewContact", label: "Xem danh bạ điện tử.", type: "e_contact" },
  //* Royalty
  {
    key: "createRoyalty",
    label: "Tạo mới bản ghi nhuận bút.",
    type: "royalty",
  },
  {
    key: "indexRoyalty",
    label: "Xem danh sách bản ghi nhuận bút.",
    type: "royalty",
  },
  {
    key: "updateRoyalty",
    label: "Cập nhật thông tin bản ghi nhuận bút.",
    type: "royalty",
  },
  //* administrator
  {
    key: "activateUser",
    label: "Kích hoạt tài khoản người dùng.",
    type: "administrator",
  },
  {
    key: "deactivateUser",
    label: "Vô hiệu hóa tài khoản người dùng.",
    type: "administrator",
  },
  {
    key: "indexUser",
    label: "Xem danh sách tài khoản người dùng.",
    type: "administrator",
  },
  {
    key: "joinUser",
    label: "Thêm người dùng vào nhóm/phòng ban.",
    type: "administrator",
  },
  {
    key: "passwordUser",
    label: "Đặt lại mật khẩu người dùng.",
    type: "administrator",
  },
  {
    key: "updateUser",
    label: "Cập nhật thông tin tài khoản người dùng.",
    type: "administrator",
  },
  {
    key: "viewUser",
    label: "Xem thông tin tài khoản người dùng.",
    type: "administrator",
  },
  //* Report royalty
  {
    key: "editorBoardReportRoyalty",
    label: "Xem báo cáo nhuận bút cho ban biên tập.",
    type: "report_royalty",
  },
  {
    key: "exportReportRoyalty",
    label: "Xuất báo cáo nhuận bút.",
    type: "report_royalty",
  },
  {
    key: "generalReportRoyalty",
    label: "Xem báo cáo nhuận bút tổng quan.",
    type: "report_royalty",
  },
  {
    key: "memberReportRoyalty",
    label: "Xem báo cáo nhuận bút cá nhân.",
    type: "report_royalty",
  },
  {
    key: "postedExportReportRoyalty",
    label: "Xuất báo cáo nhuận bút đã đăng.",
    type: "report_royalty",
  },
  {
    key: "postedReportRoyalty",
    label: "Xem báo cáo nhuận bút đã đăng.",
    type: "report_royalty",
  },
  {
    key: "postReportRoyalty",
    label: "Đăng báo cáo nhuận bút.",
    type: "report_royalty",
  },
  {
    key: "exportMemberReportRoyalty",
    label: "Xuất báo cáo nhuận bút theo tác giả.",
    type: "report_royalty",
  },
  {
    key: "mediaReportRoyalty",
    label: "Xem báo cáo nhuận bút media.",
    type: "report_royalty",
  },
  {
    key: "mediaExportReportRoyalty",
    label: "Xuất báo cáo nhuận bút media.",
    type: "report_royalty",
  },
  //* Menu
  { key: "createMenu", label: "Tạo menu mới.", type: "menu" },
  { key: "deleteMenu", label: "Xóa menu.", type: "menu" },
  { key: "indexMenu", label: "Xem danh sách menu.", type: "menu" },
  { key: "updateMenu", label: "Cập nhật thông tin menu.", type: "menu" },
  //*banner
  { key: "createBanner", label: "Tạo banner mới.", type: "banner" },
  { key: "indexBanner", label: "Xem danh sách banner.", type: "banner" },
  { key: "updateBanner", label: "Cập nhật thông tin banner.", type: "banner" },
  //* footer
  { key: "indexFooter", label: "Xem thông tin footer.", type: "footer" },
  { key: "saveFooter", label: "Lưu thay đổi cho footer.", type: "footer" },
  //* sidebar
  { key: "createSidebar", label: "Tạo thanh bên mới.", type: "sidebar" },
  { key: "deleteSidebar", label: "Xóa thanh bên.", type: "sidebar" },
  { key: "indexSidebar", label: "Xem danh sách thanh bên.", type: "sidebar" },
  //* Association link
  {
    key: "createAssociationLink",
    label: "Tạo liên kết cha mới.",
    type: "association_link",
  },
  {
    key: "deleteAssociationLink",
    label: "Xóa liên kết cha.",
    type: "association_link",
  },
  {
    key: "indexAssociationLink",
    label: "Xem danh sách liên kết cha.",
    type: "association_link",
  },
  {
    key: "updateAssociationLink",
    label: "Cập nhật thông tin liên kết cha.",
    type: "association_link",
  },
  {
    key: "viewAssociationLink",
    label: "Xem thông tin liên kết cha.",
    type: "association_link",
  },
  {
    key: "createAssociationLinkLevel",
    label: "Tạo liên kết con mới.",
    type: "association_link",
  },
  {
    key: "deleteAssociationLinkLevel",
    label: "Xóa liên kết con.",
    type: "association_link",
  },
  {
    key: "indexAssociationLinkLevel",
    label: "Xem danh sách liên kết con.",
    type: "association_link",
  },
  {
    key: "toggleDisplayAssociationLinkLevel",
    label: "Hiển thị/ẩn liên kết con.",
    type: "association_link",
  },
  {
    key: "updateAssociationLinkLevel",
    label: "Cập nhật thông tin liên kết con.",
    type: "association_link",
  },
  {
    key: "viewAssociationLinkLevel",
    label: "Xem thông tin liên kết con.",
    type: "association_link",
  },
  //* Home
  {
    key: "createHomeAppearance",
    label: "Tạo giao diện trang chủ mới.",
    type: "home_setup",
  },
  {
    key: "deleteHomeAppearance",
    label: "Xóa giao diện trang chủ.",
    type: "home_setup",
  },
  {
    key: "indexHomeAppearance",
    label: "Xem danh sách giao diện trang chủ.",
    type: "home_setup",
  },
  //* page member
  {
    key: "activateDomain",
    label: "Kích hoạt tên miền cho trang thành viên.",
    type: "domain",
  },
  {
    key: "createDomain",
    label: "Tạo tên miền mới cho trang thành viên.",
    type: "domain",
  },
  {
    key: "indexDomain",
    label: "Xem danh sách tên miền của trang thành viên.",
    type: "domain",
  },
  {
    key: "processDomain",
    label: "Xử lý các yêu cầu liên quan đến tên miền.",
    type: "domain",
  },
  {
    key: "updateDomain",
    label: "Cập nhật thông tin tên miền.",
    type: "domain",
  },
  {
    key: "viewDomain",
    label: "Xem thông tin chi tiết về tên miền.",
    type: "domain",
  },
  //* measerement
  {
    key: "indexMeasurement",
    label: "Xem danh sách trích đo.",
    type: "measurement",
  },
  {
    key: "deleteMeasurement",
    label: "Xoá trích đo.",
    type: "measurement",
  },
  {
    key: "measuredMeasurement",
    label: "Chuyển trạng thái thành đã đo.",
    type: "measurement",
  },
  {
    key: "refuseMeasurement",
    label: "Chuyển trạng thái thành Không đủ điều kiện.",
    type: "measurement",
  },
  {
    key: "returnedMeasurement",
    label: "Chuyển trạng thái thành Đã trả kết quả.",
    type: "measurement",
  },
  {
    key: "viewMeasurement",
    label: "Xem chi tiết trích đo.",
    type: "measurement",
  },
];

export const permissionBlocks = [
  {
    key: "1",
    label: "Bộ quyền quản lý tin bài",
    children: roleData.filter((i) => i.type === "post"),
  },
  {
    key: "2",
    label: "Bộ quyền quản lý chuyên mục tin bài",
    children: roleData.filter((i) => i.type === "category"),
  },
  {
    key: "3",
    label: "Bộ quyền quản lý lấy tin",
    children: roleData.filter((i) => i.type === "crawl_post"),
  },
  {
    key: "4",
    label: "Bộ quyền quản lý Nội dung đăng tải",
    children: roleData.filter((i) => i.type === "posted_content"),
  },
  {
    key: "5",
    label: "Bộ quyền quản lý Văn bản CĐĐH",
    children: roleData.filter((i) => i.type === "directive"),
  },
  {
    key: "6",
    label: "Bộ quyền quản lý văn bản dự thảo",
    children: roleData.filter((i) => i.type === "draft"),
  },
  {
    key: "7",
    label: "Bộ quyền quản lý thư viện media",
    children: roleData.filter((i) => i.type === "media"),
  },
  {
    key: "8",
    label: "Bộ quyền quản lý bình luận",
    children: roleData.filter((i) => i.type === "comment"),
  },
  {
    key: "9",
    label: "Bộ quyền quản lý đối thoại",
    children: roleData.filter((i) => i.type === "conversation"),
  },
  {
    key: "10",
    label: "Bộ quyền quản lý hỏi đáp",
    children: roleData.filter((i) => i.type === "question_answer"),
  },
  {
    key: "11",
    label: "Bộ quyền quản lý Phản ánh kiến nghị",
    children: roleData.filter((i) => i.type === "recommendation"),
  },
  {
    key: "12",
    label: "Bộ quyền quản lý Lịch công tác của lãnh đạo",
    children: roleData.filter((i) => i.type === "leader_schedule"),
  },
  {
    key: "13",
    label: "Bộ quyền quản lý Đường dây nóng",
    children: roleData.filter((i) => i.type === "hotline"),
  },
  {
    key: "14",
    label: "Bộ quyền quản lý Danh bạ điện tử",
    children: roleData.filter((i) => i.type === "e_contact"),
  },
  {
    key: "15",
    label: "Bộ quyền quản lý Nhuận bút",
    children: roleData.filter((i) => i.type === "royalty"),
  },
  {
    key: "16",
    label: "Bộ quyền quản lý Quản trị viên",
    children: roleData.filter((i) => i.type === "administrator"),
  },
  {
    key: "17",
    label: "Bộ quyền quản lý Báo cáo thống kê nhuận bút",
    children: roleData.filter((i) => i.type === "report_royalty"),
  },
  {
    key: "18",
    label: "Bộ quyền quản lý Menu",
    children: roleData.filter((i) => i.type === "menu"),
  },
  {
    key: "19",
    label: "Bộ quyền quản lý Banner",
    children: roleData.filter((i) => i.type === "banner"),
  },
  {
    key: "20",
    label: "Bộ quyền quản lý Chân trang",
    children: roleData.filter((i) => i.type === "footer"),
  },
  {
    key: "21",
    label: "Bộ quyền quản lý Thanh bên",
    children: roleData.filter((i) => i.type === "sidebar"),
  },
  {
    key: "22",
    label: "Bộ quyền quản lý Danh mục liên kết",
    children: roleData.filter((i) => i.type === "association_link"),
  },
  {
    key: "23",
    label: "Bộ quyền quản lý Thiết lập trang chủ",
    children: roleData.filter((i) => i.type === "home_setup"),
  },
  {
    key: "24",
    label: "Bộ quyền quản lý Trang thành viên",
    children: roleData.filter((i) => i.type === "domain"),
  },
  {
    key: "25",
    label: "Bộ quyền quản lý Trích đo",
    children: roleData.filter((i) => i.type === "measurement"),
  },
];
